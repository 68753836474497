@tailwind base;
@tailwind components;
@tailwind utilities;

p:not(.no-color-transition),
div:not(.no-color-transition) {
  @apply transition-colors duration-300;
}

body {
  @apply overflow-hidden;
}

#canvas-design {
  height: 100vh;
	--sz: 10px; /*** size ***/
	--c0: #1a1a1a;
	--c1: #8a8a8a;
  --c2: #383838;
  --c3: #696969;
  --ts: 50%/ calc(var(--sz) * 12.8) calc(var(--sz) * 22);
  background:
		/*bottom*/
    conic-gradient(from 120deg at 50% 86.5%, var(--c1) 0 120deg, #fff0 0 360deg) var(--ts),
    conic-gradient(from 120deg at 50% 86.5%, var(--c1) 0 120deg, #fff0 0 360deg) var(--ts),
		/*bottom dark*/
		conic-gradient(from 120deg at 50% 74%, var(--c0) 0 120deg, #fff0 0 360deg) var(--ts),
		/*right*/
		conic-gradient(from 60deg at 60% 50%, var(--c1) 0 60deg, var(--c2) 0 120deg, #fff0 0 360deg) var(--ts),
		/*left*/
		conic-gradient(from 180deg at 40% 50%, var(--c3) 0 60deg, var(--c1) 0 120deg, #fff0 0 360deg) var(--ts),
		/*top dark*/
    conic-gradient(from 0deg at 90% 35%, var(--c0) 0 90deg, #fff0 0 360deg) var(--ts),
    conic-gradient(from -90deg at 10% 35%, var(--c0) 0 90deg, #fff0 0 360deg) var(--ts),
    conic-gradient(from 0deg at 90% 35%, var(--c0) 0 90deg, #fff0 0 360deg) var(--ts),
    conic-gradient(from -90deg at 10% 35%, var(--c0) 0 90deg, #fff0 0 360deg) var(--ts),
		/*top*/
    conic-gradient(from -60deg at 50% 13.5%, var(--c1) 0 120deg, #fff0 0 360deg) var(--ts),
    conic-gradient(from -60deg at 50% 13.5%, var(--c1) 0 120deg, #fff0 0 360deg) var(--ts),
		conic-gradient(from -60deg at 50% 41%, var(--c2) 0 60deg, var(--c3) 0 120deg, #fff0 0 360deg) var(--ts),
		var(--c0) ;
}


html,
body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0.6 !important;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #88888882;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5555557b;
}

@layer utilities {

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.mali-bold {
  font-family: "Mali", cursive;
  font-weight: 700;
  font-style: normal;
}

.wordtitle {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kode-mono-semibold {
  font-family: "Kode Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}